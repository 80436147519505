/* eslint-disable react/self-closing-comp */
/* eslint-disable @next/next/no-sync-scripts */
import 'styles/loading.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import '../styles/globals.css';
import theme from 'constants/theme';
import GlobalStyle from 'styles/GlobalStyle';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useAtom } from 'jotai';
import authAtom from 'stores/authAtom';
import axios from 'axios';
import { useRouter } from 'next/router';
import { initializeApp } from 'firebase/app';
import * as ga from 'net/ga';
import { getSystemLang } from 'utils/getSystemLang';
import { langSeparateLandingArr } from 'constants/PATHNAME';

import langAtom from 'stores/langAtom';

declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    Kakao: any;
  }
}

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_CUSTOM_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  appId: process.env.FIREBASE_APP_ID,
};

try {
  initializeApp(firebaseConfig);
} catch (e) {}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        meta: require('../langs/en/meta.json'),
        headerFooterMenu: require('../langs/en/header-footer-menu.json'),
        wordCounter: require('../langs/en/word-counter.json'),
        grammarCheck: require('../langs/en/grammar-check.json'),
        pricing: require('../langs/en/pricing.json'),
        aboutUs: require('../langs/en/about-us.json'),
        paraphrase: require('../langs/en/paraphrase.json'),
        translate: require('../langs/en/translate.json'),
        resumeBuilder: require('../langs/en/resume-builder.json'),
        landing: require('../langs/en/landing.json'),
        proofread: require('../langs/en/proofread.json'),
        auth: require('../langs/en/auth.json'),
        paraphrasing: require('../langs/en/paraphrasing.json'),
        // transProduct: require('../langs/en/translation.json'),
        payment: require('../langs/en/payment.json'),
        account: require('../langs/en/account.json'),
        accountEtc: require('../langs/en/account-etc.json'),
        etc: require('../langs/en/etc.json'),
        b2b: require('../langs/en/b2b.json')
      },
      ko: {
        meta: require('../langs/ko/meta.json'),
        headerFooterMenu: require('../langs/ko/header-footer-menu.json'),
        wordCounter: require('../langs/ko/word-counter.json'),
        grammarCheck: require('../langs/ko/grammar-check.json'),
        pricing: require('../langs/ko/pricing.json'),
        aboutUs: require('../langs/ko/about-us.json'),
        paraphrase: require('../langs/ko/paraphrase.json'),
        translate: require('../langs/ko/translate.json'),
        resumeBuilder: require('../langs/ko/resume-builder.json'),
        landing: require('../langs/ko/landing.json'),
        proofread: require('../langs/ko/proofread.json'),
        auth: require('../langs/ko/auth.json'),
        paraphrasing: require('../langs/ko/paraphrasing.json'),
        // transProduct: require('../langs/ko/translation.json'),
        payment: require('../langs/ko/payment.json'),
        account: require('../langs/ko/account.json'),
        accountEtc: require('../langs/ko/account-etc.json'),
        etc: require('../langs/ko/etc.json'),
        b2b: require('../langs/ko/b2b.json')
      },
      ja: {
        meta: require('../langs/ja/meta.json'),
        headerFooterMenu: require('../langs/ja/header-footer-menu.json'),
        wordCounter: require('../langs/ja/word-counter.json'),
        grammarCheck: require('../langs/ja/grammar-check.json'),
        pricing: require('../langs/ja/pricing.json'),
        aboutUs: require('../langs/ja/about-us.json'),
        paraphrase: require('../langs/ja/paraphrase.json'),
        translate: require('../langs/ja/translate.json'),
        resumeBuilder: require('../langs/ja/resume-builder.json'),
        landing: require('../langs/ja/landing.json'),
        proofread: require('../langs/ja/proofread.json'),
        auth: require('../langs/ja/auth.json'),
        paraphrasing: require('../langs/ja/paraphrasing.json'),
        // transProduct: require('../langs/ja/translation.json'),
        payment: require('../langs/ja/payment.json'),
        account: require('../langs/ja/account.json'),
        accountEtc: require('../langs/ja/account-etc.json'),
        etc: require('../langs/ja/etc.json'),
        b2b: require('../langs/ja/b2b.json')
      },
      cn: {
        meta: require('../langs/cn/meta.json'),
        headerFooterMenu: require('../langs/cn/header-footer-menu.json'),
        wordCounter: require('../langs/cn/word-counter.json'),
        grammarCheck: require('../langs/cn/grammar-check.json'),
        pricing: require('../langs/cn/pricing.json'),
        aboutUs: require('../langs/cn/about-us.json'),
        paraphrase: require('../langs/cn/paraphrase.json'),
        translate: require('../langs/cn/translate.json'),
        resumeBuilder: require('../langs/cn/resume-builder.json'),
        landing: require('../langs/cn/landing.json'),
        proofread: require('../langs/cn/proofread.json'),
        auth: require('../langs/cn/auth.json'),
        paraphrasing: require('../langs/cn/paraphrasing.json'),
        // transProduct: require('../langs/cn/translation.json'),
        payment: require('../langs/cn/payment.json'),
        account: require('../langs/cn/account.json'),
        accountEtc: require('../langs/cn/account-etc.json'),
        etc: require('../langs/cn/etc.json'),
        b2b: require('../langs/cn/b2b.json')
      },
      es: {
        meta: require('../langs/es/meta.json'),
        headerFooterMenu: require('../langs/es/header-footer-menu.json'),
        wordCounter: require('../langs/es/word-counter.json'),
        grammarCheck: require('../langs/es/grammar-check.json'),
        pricing: require('../langs/es/pricing.json'),
        aboutUs: require('../langs/es/about-us.json'),
        paraphrase: require('../langs/es/paraphrase.json'),
        translate: require('../langs/es/translate.json'),
        resumeBuilder: require('../langs/es/resume-builder.json'),
        landing: require('../langs/es/landing.json'),
        proofread: require('../langs/es/proofread.json'),
        auth: require('../langs/es/auth.json'),
        paraphrasing: require('../langs/es/paraphrasing.json'),
        // transProduct: require('../langs/es/translation.json'),
        payment: require('../langs/es/payment.json'),
        account: require('../langs/es/account.json'),
        accountEtc: require('../langs/es/account-etc.json'),
        etc: require('../langs/es/etc.json'),
        b2b: require('../langs/es/b2b.json')
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    returnNull: false,

    interpolation: {
      escapeValue: false,
    },
    /*
    react: {
      transSupportBasicHtmlNodes: true
    }
    */
  });

function MyApp({ Component, pageProps }: AppProps) {
  const { t } = useTranslation();
  const [, setAuth] = useAtom(authAtom);
  const [lang, setLang] = useAtom(langAtom);

  const initialOptions = {
    'client-id': process.env.PAYPAL_CLIENT_ID || '',
    currency: 'USD',
    vault: true,
    components: 'buttons',
    intent: 'subscription',
  };
  const router = useRouter();
  const { pathname } = router;

  const cookies = new Cookies();
  const token = cookies.get('token');
  const cookieMaxAge = Number(process.env.COOKIE_MAX_AGE);

  // todo. check if this applies to paraphrase.
  const isMbSwipePage = router.pathname === '/proofread-mb';

  // axios 헤더 할당은 가능한 빠른 시점에 한다
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  // --------------------------------------------------------------------------------------------
  // -------- Hooks -----------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------
  useEffect(() => {
    // lang setup
    // If user visited the following pages, then it is required to override language.
    // No use of the current cookie language. (just save)
    if (langSeparateLandingArr.some(item => item === pathname)) {
      let userLang: string;
      if (router.pathname.startsWith('/ko')) {
        // Korean page
        userLang = 'ko';
      } else if (router.pathname.startsWith('/ja')) {
        // Japanese page
        userLang = 'ja';
      } else if (router.pathname.startsWith('/cn')) {
        // Chinese page
        userLang = 'cn';
      } else if (router.pathname.startsWith('/es')) {
        // Spanish page
        userLang = 'es';
      } else {
        // English page (default)
        userLang = 'en';
      }
      cookies.set('user-lang', userLang, { path: '/', maxAge: cookieMaxAge });
      setLang(userLang);
      i18n.changeLanguage(userLang);
    } else if (lang === 'init') {
      const acceptLangArr = window.navigator.languages.map(item => item.substring(0, 2));
      const userLang = cookies.get('user-lang') || getSystemLang(acceptLangArr) || 'en';

      // setting lang atom and i18n lang
      i18n.changeLanguage(userLang);
      setLang(userLang);
    }
  }, []);

  useEffect(() => {
    if (lang !== 'init') {
      cookies.set('user-lang', lang, { path: '/', maxAge: cookieMaxAge });
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  useEffect(() => {
    // 자동 로그인
    if (token) {
      setAuth(auth => ({ ...auth, token, loaded: true }));
    } else {
      setAuth(auth => ({ ...auth, loaded: true }));
    }
  }, []);

  useEffect(() => {
    // 초기화 이후에 401 에러가 발생하면 로그인 페이지로
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          // request is success. However, the server replied it with code except 2XX.
          if (error.response.status === 401) {
            // 쿠키, 상태, axios 헤더 제거 후 로그인 페이지로
            cookies.remove('token');
            setAuth(auth => ({ ...auth, token: null, user: null }));
            delete axios.defaults.headers.common.Authorization;
            router.push(`/auth/sign-in?ref=${window.location.pathname}`);
          } else {
            return Promise.reject(error);
          }
        } else if (error.request) {
          // the request was successful but the client didn't get any answer
          // todo. log error
          return Promise.reject(error);
        } else {
          // there was an error while requesting
          // todo. log error
          return Promise.reject(error);
        }
      },
    );
  }, []); // Called only at the first rendering

  // Change lang attribute of the HTML document upon "lang" change
  useEffect(() => {
    document.querySelector('html')?.setAttribute('lang', lang?.split('-')?.shift() || 'en');
  }, [lang]);

  // Kakao init
  useEffect(() => {
    try {
      if (!window.Kakao.isInitialized()) {
        window.Kakao.init(process.env.KAKAO_API_JS_KEY);
      }
    } catch (e) {
      console.warn(e);
    }
  }, []);

  // GA
  useEffect(() => {
    const handleRouteChange = (url: any) => {
      ga.pageview(url);
    };
    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // userAgent
  useEffect(() => {
    // show in app browser alert when user accesses any engram page from kakaostory in app browser
    const userAgentCheckList = ['KAKAOSTORY'].map(item => {
      return navigator.userAgent.includes(item);
    });
    if (userAgentCheckList.includes(true)) {
      alert(t('alert.inAppBrowserAlert', { ns: 'etc' }));
    }
  }, []);
  // --------------------------------------------------------------------------------------------

  const ogSiteName = t('ogSiteName', { ns: 'meta' });
  const title = t('_app.title', { ns: 'meta' });
  const description = t('_app.description', { ns: 'meta' });
  const keywords = t('_app.keywords', { ns: 'meta' });
  const ogImage = t('_app.ogImage', { ns: 'meta' });
  const canonical = t('_app.canonical', { ns: 'meta' });

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} key="og:title" />

        <meta property="og:site_name" content={ogSiteName} key="og:site_name" />

        <link rel="icon" href="/images/favicon.svg" type="image/svg+xml" />
        <link rel="icon" href="/images/favicon.ico" />
        {/* fallback favicon if browser doesn't support svg favicon */}
        <link rel="shortcut icon" type="image/png" href="https://www.engram.us/images/favicon.png" />

        <link rel="canonical" href={canonical} key="canonical" />
        <meta property="og:url" content={canonical} key="og:url" />

        <meta property="og:image" key="og:image" content={ogImage} />

        <meta name="description" content={description} key="description" />
        <meta property="og:description" content={description} key="og:description" />

        <meta name="keywords" content={keywords} key="keywords" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      </Head>
      <PayPalScriptProvider options={initialOptions}>
        {/* {pathname === '/proofread' || pathname === '/resume-builder'
          ? (<Component {...pageProps} />)
          : (
            <ThemeProvider theme={theme}>
              {isMbSwipePage ? <GlobalStyleProofreadMb /> : <GlobalStyle />}
              <Component {...pageProps} />
            </ThemeProvider>
          )} */}
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Component {...pageProps} />
        </ThemeProvider>
      </PayPalScriptProvider>
    </>
  );
}
export default MyApp;
